define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-tools-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAiToolsNewRoute extends _discourse.default {
    beforeModel(transition) {
      this.preset = transition.to.queryParams.presetId || "empty_tool";
    }
    async model() {
      return this.store.createRecord("ai-tool");
    }
    setupController(controller) {
      super.setupController(...arguments);
      const toolsModel = this.modelFor("adminPlugins.show.discourse-ai-tools");
      controller.set("allTools", toolsModel);
      controller.set("presets", toolsModel.resultSetMeta.presets);
      controller.set("llms", toolsModel.resultSetMeta.llms);
      controller.set("settings", toolsModel.resultSetMeta.settings);
      controller.set("selectedPreset", this.preset);
    }
  }
  _exports.default = DiscourseAiToolsNewRoute;
});